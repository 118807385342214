import { rafflesEnum } from 'common/enums/AfterSales'
import { IRaffle } from 'components/RafflesCarousel/RafflesCarousel.types'

import { formatToBrazilianDate } from '@interco/lib-util'

export const convertCurrentDateTimeToString = (strTimestamp: string) =>
  formatToBrazilianDate(new Date(strTimestamp))

export const convertYearMonthDayToString = (dateString: string) => {
  const dateArr = dateString.split('-')
  return dateArr.length === 3 ? `${`${dateArr[2]}/${dateArr[1]}/${dateArr[0]}`}` : ''
}

/**
 * Define o scroll no topo da página.
 */
export const scrollToTop = () => {
  window.scrollTo(0, 0)
}

export const sortRaffles = (raffles: IRaffle[]) => {
  const nextRaffleIndex = raffles.findIndex((raffleId) => raffleId.status === rafflesEnum.NEXT)

  let sortedRaffles = raffles

  if (nextRaffleIndex > 1) {
    sortedRaffles = [
      ...raffles.slice(nextRaffleIndex - 1),
      ...raffles.slice(0, nextRaffleIndex - 1),
    ]
  }
  return sortedRaffles
}
