import { useState } from 'react'

import { Svg } from 'assets/svg/svg'
import { rafflesEnum } from 'common/enums/AfterSales'
import { Separator } from 'components/Separator/Separator'
import {
  AnalyticsTicketDetailsRaffle,
  AnalyticsTicketRaffleDetails,
  AnalyticsTicketRaffleDetailsWin,
} from 'pages/afterSales/AfterSalesDetails/AfterSalesDetails.tags'
import { convertYearMonthDayToString } from 'utils/commons'

import { Alert } from '@interco/inter-ui/components/Alert'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Button } from '@interco/inter-ui/components/Button'
import { Carousel } from '@interco/inter-ui/components/Carousel'
import { Flex } from '@interco/inter-ui/components/Flex'
import { Tag } from '@interco/inter-ui/components/Tag'
import { Text } from '@interco/inter-ui/components/Text'
import { formatToBRL } from '@interco/lib-util'

import * as S from './RafflesCarousel.styles'
import { ICarouselProps, IRaffle, IRaffleData } from './RafflesCarousel.types'

export const RafflesCarousel = ({ luckyNumber, raffles }: ICarouselProps) => {
  const [showBottomSheet, setShowBottomSheet] = useState(false)
  const [raffleDetails, setRaffleDetails] = useState<IRaffle | null>(null)

  const Labels: Record<rafflesEnum, string> = {
    [rafflesEnum.WIN]: 'Você ganhou',
    [rafflesEnum.REALIZED]: 'Sorteio Realizado',
    [rafflesEnum.NEXT]: 'Próximo sorteio',
    [rafflesEnum.SOON]: 'Em breve',
  }

  const Tags: Record<rafflesEnum, JSX.Element> = {
    [rafflesEnum.WIN]: (
      <S.TagContent>
        <Tag size="small" theme="green">
          {Labels[rafflesEnum.WIN]}
        </Tag>
      </S.TagContent>
    ),
    [rafflesEnum.REALIZED]: (
      <S.TagContent>
        <Tag size="small" color="var(--gray200)" colorText="var(--gray500)">
          {Labels[rafflesEnum.REALIZED]}
        </Tag>
      </S.TagContent>
    ),
    [rafflesEnum.NEXT]: (
      <S.TagContent>
        <Tag size="small" theme="yellow">
          {Labels[rafflesEnum.NEXT]}
        </Tag>
      </S.TagContent>
    ),
    [rafflesEnum.SOON]: (
      <S.TagContent>
        <Tag size="small" theme="gray">
          {Labels[rafflesEnum.SOON]}
        </Tag>
      </S.TagContent>
    ),
  }

  const Gifts: Record<rafflesEnum, JSX.Element> = {
    [rafflesEnum.WIN]: Svg.GiftSuccess,
    [rafflesEnum.REALIZED]: Svg.GiftOpen,
    [rafflesEnum.NEXT]: Svg.Gift,
    [rafflesEnum.SOON]: Svg.Gift,
  }

  const handleCardDetails = (raffle: IRaffle, index: number) => {
    AnalyticsTicketDetailsRaffle(raffle.title, `${index + 1}`)
    setShowBottomSheet(true)
    setRaffleDetails(raffle)
  }

  const rafflesSlider = raffles.map((raffle, index) => {
    const bgColorCard = () => {
      if (raffle.status === rafflesEnum.WIN && raffle.prizeRequested) {
        return 'var(--gray200)'
      }

      if (raffle.status === rafflesEnum.WIN && !raffle.prizeRequested) {
        return 'var(--primary300)'
      }

      return 'var(--gray100)'
    }

    const giftColor = raffle.status === rafflesEnum.REALIZED || !!raffle.prizeRequested

    return (
      <S.RaffleCard
        data-testid={`card-${raffle.raffleId}`}
        key={raffle.raffleId}
        onClick={() => handleCardDetails(raffle, index)}
      >
        <S.RaffleCardGift $bgColorCard={bgColorCard()} $giftColor={giftColor}>
          {Gifts[raffle.status]}
        </S.RaffleCardGift>
        {Tags[raffle.status]}

        <Text variant="body-3" as="h3" bold colorWeight={500}>
          {raffle.title} <br />
          {formatToBRL(raffle.value)}
        </Text>
        <Text variant="caption-1" as="p">
          {convertYearMonthDayToString(raffle.date)}
        </Text>

        {/* TODO: Esconder após usuário solicitar */}
        {raffle.status === rafflesEnum.WIN && !raffle.prizeRequested && (
          <Text variant="body-3" as="h3" bold color="primary">
            Solicitar depósito
          </Text>
        )}
      </S.RaffleCard>
    )
  })

  const raffleDetailsBS = () => {
    if (raffleDetails)
      AnalyticsTicketRaffleDetails(
        Labels[raffleDetails.status],
        `${raffleDetails.value}`,
        `${luckyNumber}`,
        raffleDetails.description,
      )

    const raffleData: IRaffleData[] = [
      {
        id: 1,
        text: 'Data sorteio',
        value: convertYearMonthDayToString(raffleDetails?.date ?? ''),
      },
      {
        id: 2,
        text: 'Número da sorte',
        value: `${luckyNumber}`,
      },
      {
        id: 3,
        text: 'Valor do prêmio',
        value: formatToBRL(raffleDetails?.value as number),
      },
    ]

    return (
      <>
        {raffleDetails && (
          <>
            <Flex
              justifyContent="space-between"
              direction="row"
              alignItems="center"
              style={{ marginBottom: '24px' }}
            >
              <Text variant="body-3" as="h3" bold colorWeight={500}>
                {raffleDetails.title}
              </Text>

              {Tags[raffleDetails.status]}
            </Flex>

            {raffleDetails.status === rafflesEnum.REALIZED && (
              <>
                <Flex justifyContent="space-between" direction="row" alignItems="center">
                  <Text variant="body-3">Resultado</Text>

                  <Text variant="body-3" as="h3" bold colorWeight={500}>
                    {raffleDetails.description}
                  </Text>
                </Flex>

                <Separator borderWidth="1px" margin="16px 0 0" type="dashed" />
              </>
            )}

            {raffleData.map((data) => (
              <Flex
                key={data.id}
                justifyContent="space-between"
                direction="row"
                alignItems="center"
                style={{ marginTop: '16px' }}
              >
                <Text variant="body-3">{data.text}</Text>

                <Text variant="body-3" as="h3" bold colorWeight={500}>
                  {data.value}
                </Text>
              </Flex>
            ))}
          </>
        )}
      </>
    )
  }

  const raffleDetailsWinBS = () => {
    if (raffleDetails)
      AnalyticsTicketRaffleDetailsWin(
        `Você ganhou ${raffleDetails.value}`,
        `${raffleDetails.value}`,
        `${luckyNumber}`,
      )

    const rafflePaymentData: IRaffleData[] = [
      {
        id: 1,
        text: 'Resultado',
        value: Tags[rafflesEnum.WIN],
      },
      {
        id: 2,
        text: 'Valor do prêmio',
        value: formatToBRL(raffleDetails?.value as number),
      },
    ]

    const raffleData: IRaffleData[] = [
      {
        id: 1,
        text: 'Data sorteio',
        value: convertYearMonthDayToString(raffleDetails?.date ?? ''),
      },
      {
        id: 2,
        text: 'Número da sorte',
        value: `${luckyNumber}`,
      },
    ]

    return (
      <>
        {raffleDetails && (
          <>
            {!raffleDetails.prizeRequested && (
              <S.HeaderWin>
                <S.Scratchcard>{Gifts[raffleDetails.status]}</S.Scratchcard>
                <Text variant="headline-h1" as="h1" semiBold>
                  Você ganhou
                </Text>
                <Text variant="headline-h1" as="h1" semiBold>
                  {formatToBRL(raffleDetails.value)}
                </Text>
              </S.HeaderWin>
            )}

            <Flex
              justifyContent="space-between"
              direction="row"
              alignItems="center"
              style={{ marginBottom: '24px' }}
            >
              <Text variant="body-3" as="h3" bold colorWeight={500}>
                {raffleDetails.title}
              </Text>

              {Tags[rafflesEnum.REALIZED]}
            </Flex>

            {raffleDetails.prizeRequested &&
              rafflePaymentData.map((data) => (
                <Flex
                  key={data.id}
                  justifyContent="space-between"
                  direction="row"
                  alignItems="center"
                  style={{ marginTop: '16px' }}
                >
                  <Text variant="body-3">{data.text}</Text>

                  <Text variant="body-3" as="h3" bold colorWeight={500}>
                    {data.value}
                  </Text>
                </Flex>
              ))}

            {raffleDetails.paymentDate && (
              <Flex
                justifyContent="space-between"
                direction="row"
                alignItems="center"
                style={{ marginTop: '16px' }}
              >
                <Text variant="body-3">Data de pagamento</Text>

                <Text variant="body-3" as="h3" bold colorWeight={500}>
                  {raffleDetails.paymentDate}
                </Text>
              </Flex>
            )}

            <Separator borderWidth="1px" margin="16px 0 0" type="dashed" />

            {raffleData.map((data) => (
              <Flex
                key={data.id}
                justifyContent="space-between"
                direction="row"
                alignItems="center"
                style={{ marginTop: '16px' }}
              >
                <Text variant="body-3">{data.text}</Text>

                <Text variant="body-3" as="h3" bold colorWeight={500}>
                  {data.value}
                </Text>
              </Flex>
            ))}

            {!raffleDetails.prizeRequested && (
              <Button variant="primary" fullWidth onClick={handlePrizeRequest}>
                Solicitar depósito na minha conta
              </Button>
            )}

            {raffleDetails.prizeRequested && !raffleDetails.paymentDate && (
              <S.AlertContent>
                <Alert type="warning">
                  Seu prêmio será depositado na sua conta em até 15 dias.
                </Alert>
              </S.AlertContent>
            )}

            {raffleDetails.prizeRequested && raffleDetails.paymentDate && (
              <S.AlertContent>
                <Alert type="success">Seu prêmio já foi depositado!</Alert>
              </S.AlertContent>
            )}
          </>
        )}
      </>
    )
  }

  const handlePrizeRequest = () => {
    // TODO desabilitar botão enquanto aguarda o response do backend
    setShowBottomSheet(false)
  }

  return (
    <S.Container>
      <Carousel
        dots
        arrows={false}
        infinite={false}
        speed={500}
        slidesToShow={2}
        slidesToScroll={1}
        dotsType="medium"
        verticalMode={false}
        className="slider"
      >
        {rafflesSlider}
      </Carousel>

      {raffleDetails && showBottomSheet && (
        <BottomSheet onClose={() => setShowBottomSheet(false)}>
          {raffleDetails.status === rafflesEnum.WIN ? raffleDetailsWinBS() : raffleDetailsBS()}
        </BottomSheet>
      )}
    </S.Container>
  )
}
