import { TypesRoutes } from 'routes/mixedRoutes/types'
import { StateCreator } from 'zustand'

import { NavbarState } from './types'

export const createNavbarSlice: StateCreator<NavbarState, [], [], NavbarState> = (set) => ({
  title: '',
  actionIcons: { leftIcon: '', rightIcon: '' },
  hideNavbar: false,
  backButtonCallback: undefined,
  helpButtonCallback: undefined,
  firstPage: TypesRoutes.START,
  backListener: undefined,

  setNavbarTitle: (title) => set(() => ({ title })),
  setActionIcons: (actionIcons) => set(() => ({ actionIcons })),
  setHideNavbar: (hideNavbar) => set(() => ({ hideNavbar })),
  setBackListener: (backListener) => set(() => ({ backListener })),
  setBackButtonCallback: (backButtonCallback) => set(() => ({ backButtonCallback })),
  setHelpButtonCallback: (helpButtonCallback) => set(() => ({ helpButtonCallback })),

  resetNavbar: () =>
    set(() => ({
      title: '',
      actionIcons: { leftIcon: '', rightIcon: '' },
      hideNavbar: false,
    })),
})
