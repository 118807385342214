import { Svg } from 'assets/svg/svg'
import { ActionIconEnum } from 'common/enums/ActionIcon'
import { PageTitles } from 'common/enums/PageTitles'
import { ReceiptStatusEnum } from 'common/enums/ReceiptStatus'
import { useNavBar } from 'hooks/useNavBar'
import { AlertSign, SuccessSign } from 'styles/commons'
import { Icons } from 'utils/icons'

import { Button } from '@interco/inter-ui/components/Button'
import { Flex } from '@interco/inter-ui/components/Flex'
import { Tag } from '@interco/inter-ui/components/Tag'
import { Text } from '@interco/inter-ui/components/Text'

import * as S from './Receipt.styles'

export const Receipt = () => {
  const STATUS: ReceiptStatusEnum = ReceiptStatusEnum.ACTIVE

  useNavBar({
    navbarTitle: PageTitles.SCRATCHCARD,
    actionIcons: {
      rightIcon: ActionIconEnum.HOME,
    },
  })

  const receiptIcon = {
    success: <Icons.CheckCircle width="32px" height="32px" color="var(--neutral-theme)" />,
    pending: <Icons.Pending width="32px" height="32px" color="var(--neutral-theme)" />,
    right: <Icons.ChevronRight width="16px" height="16px" color="var(--primary500)" />,
  }

  const receiptDataView = [
    {
      label: 'Quantidade',
      value: 10,
    },
    {
      label: 'Total',
      value: 30,
    },
    {
      label: 'Forma de pagamento',
      value: 'Cartão de crédito',
    },
    {
      label: 'Data do pagamento',
      value: 'Quinta, 00/00/0000',
    },
    {
      label: 'Protocolo',
      value: '202111120001',
    },
  ]

  const renderSuccess = (
    <S.Header>
      <SuccessSign>{receiptIcon.success}</SuccessSign>
      <Text variant="headline-h2" as="h2" semiBold>
        Compra realizada com sucesso!
      </Text>
    </S.Header>
  )

  const renderProcess = (
    <S.Header>
      <AlertSign>{receiptIcon.pending}</AlertSign>
      <Text variant="headline-h2" as="h2" semiBold>
        Estamos processando a sua compra
      </Text>
    </S.Header>
  )

  const renderData = (
    <S.Data>
      {receiptDataView.map((item) => (
        <Flex key={item.label} direction="row">
          <Text variant="caption-1">{item.label}</Text>
          <Text variant="caption-1" bold colorWeight={500}>
            {item.value}
          </Text>
        </Flex>
      ))}
    </S.Data>
  )

  const renderLuckCard = (
    <S.LuckCard>
      <Flex direction="row" gap="16px" alignItems="center">
        <div>{Svg.GiftGroup}</div>

        {STATUS === ReceiptStatusEnum.ACTIVE ? (
          <Flex direction="column" gap="8px">
            <Text variant="body-3" colorWeight={500} bold>
              Sua Sortezinha está pronta
            </Text>
            <Button variant="link" icon={receiptIcon.right}>
              Raspar
            </Button>
          </Flex>
        ) : (
          <Flex direction="column" gap="8px">
            <S.TagContent>
              <Tag theme="yellow">Preparando sua sortezinha</Tag>
            </S.TagContent>
            <Text variant="caption-1" colorWeight={500}>
              Vamos te mandar uma notificação quando você já puder raspar.
            </Text>
          </Flex>
        )}
      </Flex>
    </S.LuckCard>
  )

  const renderStickFooter = (
    <S.Footer>
      <Button fullWidth>Compartilhar comprovante</Button>
      <Button fullWidth variant="secondary">
        Voltar para a home
      </Button>
    </S.Footer>
  )

  const renderHeader: Record<ReceiptStatusEnum, JSX.Element> = {
    ACTIVE: renderSuccess,
    PROCESS: renderProcess,
  }

  return (
    <S.Container>
      {renderHeader[STATUS]}

      {renderData}

      {renderLuckCard}

      {renderStickFooter}
    </S.Container>
  )
}
