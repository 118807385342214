import { AfterSales, AfterSalesDetails, Subscription } from 'pages/afterSales'
import { Home, Receipt } from 'pages/landingPage'
import { Game, MyTickets, Start } from 'pages/scratchcard'
import { Faq } from 'pages/shared'

import { TypesRoutes as Routes } from './types'

export const mixedRoutes = [
  // SCRATCHCARD
  { route: Routes.START, component: Start },
  { route: Routes.GAME, component: Game },
  { route: Routes.MY_TICKETS, component: MyTickets },

  // AFTER SALES
  { route: Routes.AFTER_SALES, component: AfterSales },
  { route: Routes.AFTER_SALES_DETAILS, component: AfterSalesDetails },
  { route: Routes.SUBSCRIPTION, component: Subscription },

  // LANDING PAGE
  { route: Routes.HOME, component: Home },
  { route: Routes.RECEIPT, component: Receipt },

  // SHARED
  { route: Routes.FAQ, component: Faq },
]

export * from './types'
