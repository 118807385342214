import styled, { keyframes } from 'styled-components'

const animateBackground = keyframes`
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
`

const animateGift = keyframes`
  0% { transform: scale(1, 1); }
  25% { transform: scale(0.9, 1.1); }
  50% { transform: scale(1.1, 0.9); }
  100% { transform: scale(1, 1); }
`

export const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 54px);
`

export const Elipse = styled.div`
  min-width: 425px;
  height: 425px;
  border-radius: 50%;
  box-shadow: inset 0 0 70px 40px var(--neutral-theme);
  background-image: repeating-conic-gradient(
    rgba(234, 113, 0, 0.1) 0deg,
    rgba(234, 113, 0, 0.1) 6deg,
    var(--neutral-theme) 6deg,
    var(--neutral-theme) 12deg
  );

  animation: ${animateBackground} 50s linear infinite;
`

export const List = styled.div`
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  > :nth-child(2) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;

    margin: auto;
    width: 140px;
    height: 224px;

    transform: translateY(50%) rotate(-180deg) scale(0);
    transition: all 1s ease-in-out;
    opacity: 0;

    h3 {
      padding: 16px 8px;
      text-align: center;
      color: var(--auxiliary-lime-100);
      border-top: 2px dashed #f5a15180;
    }

    > svg {
      width: 66px;
      height: 70px;
      animation: ${animateGift} 1s infinite alternate;
      animation-delay: 1.8s;
    }
  }

  > :first-child,
  > :last-child {
    position: absolute;
    top: 0;
    bottom: -15px;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 160px;
    margin: auto;
    transform-origin: bottom;
    transition: all 1s ease-in-out;
    opacity: 0;

    svg {
      width: 56px;
      height: 60px;
      opacity: 0.24;
    }
  }

  > :first-child {
    right: 0;
    left: -110px;
    transform: translate3d(100%, 0, 0) rotate(0) scale(0);
  }

  > :last-child {
    right: -110px;
    left: 0px;
    transform: translate3d(-100%, 0, 0) rotate(0) scale(0);
  }

  img {
    max-width: 100%;
    height: auto;
  }

  &.animated {
    > :nth-child(2) {
      transform: translateY(0) rotate(0) scale(1);
      opacity: 1;
    }

    > :first-child {
      transform: translate3d(0, 0, 0) rotate(-15deg) scale(1);
      opacity: 1;
      transition-delay: 0.3s;
    }

    > :last-child {
      transform: translate3d(0, 0, 0) rotate(15deg) scale(1);
      opacity: 1;
      transition-delay: 0.3s;
    }
  }
`
export const Loading = styled.div`
  width: 100%;
  margin-top: 24px;
  padding-top: 24px;
  color: var(--auxiliary-lime-100);
  border-top: 2px dashed #f5a15180;
`
