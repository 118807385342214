/**
 * Rotas disponíveis na aplicação.
 */
export enum TypesRoutes {
  // SCRATCHCARD
  START = '/',
  GAME = '/game',
  MY_TICKETS = '/my-tickets',

  // AFTER SALES
  AFTER_SALES = '/after-sales',
  AFTER_SALES_DETAILS = '/after-sales-details',
  SUBSCRIPTION = '/subscription',

  // LANDING PAGE
  HOME = '/landing-page',
  RECEIPT = '/landing-page/receipt',

  // SHARED
  FAQ = '/faq',

  // REQUEST_GO_BACK
  REQUEST_GO_BACK = 'requestGoBack',
}
