import { useEffect, useState } from 'react'

import { Svg } from 'assets/svg/svg'
import { ActionIconEnum } from 'common/enums/ActionIcon'
import { PageTitles } from 'common/enums/PageTitles'
import { PaymentMethod, ScratchCard, Separator } from 'components'
import { useNavBar } from 'hooks/useNavBar'
import useBoundState from 'store'

import { uuid } from '@interco/inter-ui'
import { Button } from '@interco/inter-ui/components/Button'
import { Counter } from '@interco/inter-ui/components/Counter'
import { Flex } from '@interco/inter-ui/components/Flex'
import { Tag } from '@interco/inter-ui/components/Tag'
import { Text } from '@interco/inter-ui/components/Text'
import { formatToBRL } from '@interco/lib-util'

import * as S from './Home.styles'

interface ISummary {
  title: string
  value?: number
  element?: JSX.Element
}

export const Home = () => {
  const formOfPayment = useBoundState((state) => state.formOfPayment)

  const [isAnimated, setIsAnimated] = useState(false)
  const [value, setValue] = useState(1)

  const TICKET_VALUE = 3

  useNavBar({
    navbarTitle: PageTitles.SCRATCHCARD,
    actionIcons: {
      rightIcon: ActionIconEnum.HOME,
    },
  })

  useEffect(() => {
    setTimeout(() => {
      setIsAnimated(!isAnimated)
    }, 100)
  }, [])

  const renderCounter = <Counter min={1} max={10} value={value} onUpdate={setValue} />

  const payout = value * TICKET_VALUE

  const summary: ISummary[] = [
    {
      title: 'Quantidade',
      element: renderCounter,
    },
    {
      title: 'Sortezinha',
      value: TICKET_VALUE,
    },
    {
      title: 'Total',
      value: payout,
    },
  ]

  const renderSummary = summary.map((item) => (
    <Flex key={uuid()} direction="row" alignItems="center">
      <Text variant="body-3">{item.title}</Text>

      {item.element && item.element}

      {item.value && (
        <Text variant="body-3" bold colorWeight={500}>
          {formatToBRL(item.value)}
        </Text>
      )}
    </Flex>
  ))

  const handleCheckout = () => {
    console.log('handleCheckout', formOfPayment.type, payout)
  }

  const renderStickFooter = (
    <>
      <Separator borderWidth="1px" />

      <S.Footer>
        <Button
          data-testid="pay-button"
          fullWidth
          disabled={!formOfPayment.type}
          onClick={handleCheckout}
        >
          {`Pagar ${formatToBRL(payout)}`}
        </Button>

        <Text variant="caption-1" colorWeight={500}>
          Declaro estar de acordo com <S.Link onClick={() => true}>Condições Gerais</S.Link>,{' '}
          <S.Link onClick={() => true}>Informações legais</S.Link> e{' '}
          <S.Link onClick={() => true}>Ficha de Cadastro</S.Link> da Sortezinha.
        </Text>
      </S.Footer>
    </>
  )

  return (
    <S.Container>
      <S.Section>
        <S.List className={isAnimated ? 'animated' : ''}>
          <ScratchCard scale={300}>{Svg.Gift}</ScratchCard>
          <ScratchCard scale={300}>{Svg.GiftSuccess}</ScratchCard>
          <ScratchCard scale={300}>{Svg.Gift}</ScratchCard>
        </S.List>

        <S.Header>
          <Tag theme="green">Por apenas R$ 3,00</Tag>
          <Text variant="headline-h1" as="h1" semiBold>
            Ative sua sorte e ganhe até R$ 100,00 na hora!
          </Text>

          <S.HeaderList>
            <Text variant="body-3" as="li" colorWeight={500}>
              Concorra também a 6 sorteios de R$ 7.000,00 ao longo de um ano.
            </Text>
            <Text variant="body-3" as="li" colorWeight={500}>
              Receba 50% de cashback do valor pago após os sorteios.
            </Text>
          </S.HeaderList>
        </S.Header>

        <S.Summary>
          <Text variant="headline-h3" as="h3" semiBold>
            Resumo
          </Text>

          {renderSummary}
        </S.Summary>

        <S.Payment>
          <Text variant="headline-h3" as="h3" semiBold>
            Como você quer pagar?
          </Text>
          <PaymentMethod amount={payout} />
        </S.Payment>
      </S.Section>

      {renderStickFooter}
    </S.Container>
  )
}
