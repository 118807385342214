import BaseBridge from 'config/bridge/BaseBridge'
import BaseService from 'config/bridge/BaseService'
import { IAfterSalesResponse } from 'store/afterSales/types'

export default class AfterSalesService {
  static async getTickets() {
    let headers = {}

    if (BaseBridge.isBrowser()) {
      headers = {
        'x-inter-user-bank-account': (await BaseBridge.getUserInfo()).account,
      }
    }

    // TODO: teste de integração PRD
    BaseBridge.requestAnalytics('CAP_FRONT', {
      log_front: `getTickets endpoint: ${process.env.REACT_APP_API_CAPITALIZATION}/tickets`,
    })

    const response = await BaseService.doExecute<IAfterSalesResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_CAPITALIZATION}/tickets`,
      headers,
    })

    BaseBridge.requestAnalytics('CAP_FRONT', {
      log_front: 'getTickets response',
      response: response.data.toString(),
    })

    return response.data
  }
}
