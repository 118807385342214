import { defaultError } from 'common/mocks/ResponseError'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import TicketDetailsService from 'service/TicketDetailsService'
import useBoundState from 'store'
import { ErrorDetails } from 'store/error/types'
import { errorHandling, redirectRoute } from 'store/utils/provider'
import { sortRaffles } from 'utils/commons'
import { StateCreator } from 'zustand'

import { IRegistrationForm, ITicketDetails, TicketDetailsState } from './types'

export const createTicketDetailsSlice: StateCreator<
  TicketDetailsState,
  [],
  [],
  TicketDetailsState
> = (set) => ({
  currentTicketDetails: {} as ITicketDetails,
  registrationForm: {} as IRegistrationForm,

  getTicketDetails: async (payload) => {
    useBoundState.setState({ isLoading: true })

    await TicketDetailsService.getTicketDetails(payload.ticketId)
      .then((response) => {
        if (response.raffles.length > 0) {
          response.raffles = sortRaffles(response.raffles)
        }

        set({ currentTicketDetails: response })

        redirectRoute(payload)
      })
      .catch((error) => {
        const errorDetails: ErrorDetails = {
          ...defaultError,
          route: TypesRoutes.AFTER_SALES,
        }

        errorHandling(error as Error, 'AvailableTickets.getAvailableTickets', errorDetails)
      })
      .finally(() => {
        useBoundState.setState({ isLoading: false })
      })
  },

  getRegistrationForm: async () => {
    await TicketDetailsService.getRegistrationForm()
      .then((response) => {
        set({ registrationForm: { ...response } })
      })
      .catch((error) => {
        errorHandling(error as Error, 'AfterSalesService.getRegistrationForm')
      })
      .finally(() => useBoundState.setState({ isLoading: false }))
  },
})
