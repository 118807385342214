import styled, { keyframes } from 'styled-components'

const animateGift = keyframes`
  0% { transform: scale(1, 1); }
  25% { transform: scale(0.9, 1.1); }
  50% { transform: scale(1.1, 0.9); }
  100% { transform: scale(1, 1); }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  height: calc(100vh - 54px);
`

export const List = styled.div`
  position: relative;
  width: 100%;
  margin: 24px 0 32px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  > :nth-child(2) {
    position: relative;
    z-index: 5;

    width: 140px;
    height: 224px;
    margin: auto;
    padding: 32px;

    transform: translateY(50%) rotate(-180deg) scale(0);
    transition: all 1s ease-in-out;
    opacity: 0;

    h3 {
      padding: 16px 8px;
      text-align: center;
      color: var(--auxiliary-lime-100);
      border-top: 2px dashed #f5a15180;
    }

    > svg {
      animation: ${animateGift} 1s infinite alternate;
      animation-delay: 1.8s;
    }
  }

  > :first-child,
  > :last-child {
    position: absolute;
    top: 32px;

    width: 100px;
    height: 160px;
    margin: auto;
    transform-origin: bottom;
    transition: all 1s ease-in-out;
    opacity: 0;

    svg {
      position: absolute;
      z-index: -10;
      width: 56px;
      height: 60px;
      mix-blend-mode: luminosity;
    }
  }

  > :first-child {
    right: 0;
    left: -110px;
    transform: translate3d(100%, 0, 0) rotate(0) scale(0);
  }

  > :last-child {
    right: -110px;
    left: 0px;
    transform: translate3d(-100%, 0, 0) rotate(0) scale(0);
  }

  &.animated {
    > :nth-child(2) {
      transform: translateY(0) rotate(0) scale(1);
      opacity: 1;
    }

    > :first-child {
      transform: translate3d(0, 0, 0) rotate(-15deg) scale(1);
      opacity: 0.5;
      transition-delay: 0.3s;
    }

    > :last-child {
      transform: translate3d(0, 0, 0) rotate(15deg) scale(1);
      opacity: 0.5;
      transition-delay: 0.3s;
    }
  }
`
export const Header = styled.div`
  > div {
    margin-bottom: 16px;

    > span {
      display: flex;
      margin: 0;
    }
  }
`

export const HeaderList = styled.ul`
  padding-left: 24px;

  li {
    margin-top: 16px;
  }
`
export const Summary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 40px 0;
`

export const Footer = styled.div`
  margin: auto 0 24px;
`
