import { useCallback, useEffect } from 'react'

import { ActionIconEnum } from 'common/enums/ActionIcon'
import { PageTitles } from 'common/enums/PageTitles'
import { ScratchCard } from 'components/ScratchCard/ScratchCard'
import BaseBridge from 'config/bridge/BaseBridge'
import { useNavBar } from 'hooks/useNavBar'
import { useHistory } from 'react-router-dom'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import useBoundState from 'store'

import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'

import * as S from './Game.styles'
import { AnalyticsGameAll, AnalyticsGameHeader } from './Game.tags'
import { GameResult } from './GameResult/GameResult'
import { ScratchCardsGrid } from './ScratchCardsGrid/ScratchCardsGrid'

export const Game = () => {
  const history = useHistory()

  const { cardNumbers, ticketAwarded, luckyNumber, prizeAmount } = useBoundState(
    (state) => state.currentTicket,
  )
  const tickets = useBoundState((state) => state.tickets)
  const availableTickets = useBoundState((state) => state.availableTickets)
  const isLoading = useBoundState((state) => state.isLoading)
  const isAllScratch = useBoundState((state) => state.isAllScratch)
  const completeTicket = useBoundState((state) => state.completeTicket)
  const getCompleteTicket = useBoundState((state) => state.getCompleteTicket)

  const VALUE_TO_WIN = 3
  const HAS_RESULT = Object.keys(completeTicket).length !== 0
  const HAS_TICKETS_AFTER_SALES = Object.keys(tickets).length !== 0

  const handleBack = useCallback(() => {
    if (HAS_TICKETS_AFTER_SALES && availableTickets.length === 1) {
      setTimeout(() => history.push(TypesRoutes.AFTER_SALES), 50)
      return
    }

    if (availableTickets.length === 1) {
      BaseBridge.requestGoBack()
      return
    }

    history.goBack()
  }, [HAS_TICKETS_AFTER_SALES, availableTickets, history])

  useNavBar({
    navbarTitle: PageTitles.SCRATCHCARD,
    actionIcons: {
      leftIcon: ActionIconEnum.BACK,
      rightIcon: ActionIconEnum.FAQ,
    },
    backListener: handleBack,
    backButtonCallback: () => AnalyticsGameHeader('10', 'back'),
    helpButtonCallback: () => AnalyticsGameHeader('12', 'ajuda'),
  })

  const finishGame = useCallback(() => {
    const canvas = document.querySelectorAll('canvas')
    if (!canvas) return

    canvas.forEach(async (item, index) => {
      const ctx = item.getContext('2d')
      if (!ctx) return

      let x = 0
      let y = 0
      const radius = 30
      const speed = 10

      const draw = () => {
        if (y >= item.height) return

        ctx.globalCompositeOperation = 'destination-out'
        ctx.beginPath()
        ctx.arc(x, y, radius, 0, Math.PI * 2, true)
        ctx.fill()

        x += speed
        if (x >= item.width) {
          x = 0
          y += radius * 2
        }

        requestAnimationFrame(draw)
      }

      draw()

      if (index === canvas.length - 1) {
        getCompleteTicket()
      }
    })
  }, [getCompleteTicket])

  const handleScratchAll = useCallback(() => {
    AnalyticsGameAll()
    finishGame()
  }, [finishGame])

  useEffect(() => {
    BaseBridge.tacWb({
      name: 'load_sortezinha',
      module: 'capitalizacao',
      actions: ['enter_game'],
      params: {},
    })
  }, [])

  useEffect(() => {
    if (!cardNumbers || cardNumbers.length === 0) return

    const allRevealed = cardNumbers.every((card) => card.revealed)
    const revealedMatchingCards = cardNumbers.filter(
      (card) => card.revealed && card.value === prizeAmount,
    ).length

    if (allRevealed) {
      finishGame()
    } else if (ticketAwarded && revealedMatchingCards === VALUE_TO_WIN) {
      finishGame()
    }
  }, [finishGame, cardNumbers, prizeAmount, ticketAwarded])

  const renderStickFooter = (
    <S.Footer>
      <Button
        variant="secondary"
        fullWidth
        onClick={handleScratchAll}
        disabled={isAllScratch}
        isLoading={isLoading}
      >
        Raspar todos
      </Button>
    </S.Footer>
  )

  return (
    <S.Container $blockScroll={HAS_RESULT} data-testid="game">
      <S.Header>
        <Text variant="headline-h1" as="h1" semiBold>
          Raspou, achou, ganhou!
        </Text>
        <Text variant="body-3" as="p">
          Encontre 3 valores iguais e ganhe na hora!
        </Text>
      </S.Header>
      <S.ScratchCardContent>
        <ScratchCard scale={500}>
          <ScratchCardsGrid cards={cardNumbers} />
          <Text variant="caption-2" bold as="p">
            {`Sortezinha #${luckyNumber}`}
          </Text>
        </ScratchCard>
      </S.ScratchCardContent>

      {renderStickFooter}

      {HAS_RESULT && <GameResult ticketAwarded={ticketAwarded} />}
    </S.Container>
  )
}
