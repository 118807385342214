import React from 'react'

import { ListDescription } from 'components/ListDescription/ListDescription'
import { CheckoutBaseService } from 'config/bridge/CheckoutBaseService'
import NewRelicUtils from 'config/monitoring/NewRelicUtils'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import useBoundState from 'store'
import { Icons } from 'utils/icons'

import { Text } from '@interco/inter-ui/components/Text'

import { ContentPayment } from './PaymentMethod.styles'

interface PaymentMethodProps {
  amount: number
}

export const PaymentMethod = ({ amount }: PaymentMethodProps) => {
  const formOfPayment = useBoundState((state) => state.formOfPayment)
  const setFormOfPayment = useBoundState((state) => state.setFormOfPayment)
  const setHasError = useBoundState((state) => state.setHasError)

  const walletIcons = {
    payment: <Icons.Money width={24} height={24} color="var(--gray500)" />,
    edit: <Icons.Edit width={24} height={24} color="var(--primary500)" />,
    debit: <Icons.Wallet width={24} height={24} color="var(--primary500)" />,
    credit: <Icons.CreditCard width={24} height={24} color="var(--primary500)" />,
    right: <Icons.ChevronRight width={24} height={24} color="var(--gray500)" />,
  }

  const formOfPaymentIcon = (iconWallet: string) => {
    let icon: React.ReactElement

    switch (iconWallet) {
      case 'DEBIT':
        icon = walletIcons.debit
        break

      case 'CREDIT':
        icon = walletIcons.credit
        break

      default:
        icon = walletIcons.payment
        break
    }

    return icon
  }

  const openChoicePaymentForm = async () => {
    try {
      const additionalFields = {}

      const additionalQueryRequestParams = additionalFields

      const response = await CheckoutBaseService.openWallet(
        `${amount}`,
        'CAPITALIZATION',
        additionalFields,
        additionalQueryRequestParams,
      )

      setFormOfPayment(response)
    } catch (error) {
      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'Hiring.openWallet',
      })

      setHasError({
        title: 'Houve um erro por aqui',
        subTitle:
          'No momento, essa funcionalidade está indisponível. Por favor, tente novamente em alguns minutos.',
        disabledButton: true,
        disabledHome: true,
        route: TypesRoutes.HOME,
      })
    }
  }

  return (
    <ContentPayment>
      <ListDescription
        data-testid="payment-methods"
        iconLeft={formOfPaymentIcon(formOfPayment.type)}
        iconRight={formOfPayment.type ? walletIcons.edit : walletIcons.right}
        withBorder
        borderColor={formOfPayment.type ? 'var(--primary500)' : 'var(--gray200)'}
        onClick={openChoicePaymentForm}
      >
        <Text variant="body-3" bold colorWeight={500}>
          {formOfPayment.title}
        </Text>
        {formOfPayment.subTitle && (
          <Text variant="body-3" style={{ marginTop: '8px' }}>
            {formOfPayment.subTitle}
          </Text>
        )}
      </ListDescription>
    </ContentPayment>
  )
}
