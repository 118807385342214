import axios, { Method } from 'axios'

import InterWebviewBridge, {
  interWbHttp,
  IWbHttpData,
  IWbHttpOptions,
} from '@interco/inter-webview-bridge'

import NewRelicUtils from '../monitoring/NewRelicUtils'
import BaseBridge from './BaseBridge'

export interface BaseRequestConfig {
  method: Method
  endpoint: string
  headers?: Record<string, string>
  data?: Record<string, unknown>
  options?: IWbHttpOptions
}

export interface BaseResponseBack<T> {
  status: number
  dateTime: string
  data: T
  message?: string
}

export class BaseService {
  static async authDev(): Promise<void> {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${process.env.REACT_APP_API_AUTH_USER}`,
    }

    const response = await axios({
      url: `${process.env.REACT_APP_API_HOST}/oauth/token?grant_type=client_credentials`,
      method: 'POST',
      headers,
    })

    axios.defaults.headers['x-api-token'] = response.data.access_token
  }

  static async callByAxios<T>(config: BaseRequestConfig): Promise<BaseResponseBack<T>> {
    const { endpoint, ...data } = config

    try {
      if (!axios.defaults.headers['x-api-token']) {
        await BaseService.authDev()
      }

      const response = await axios({
        url: `${process.env.REACT_APP_API_HOST}/${endpoint}`,
        ...data,
      })

      return {
        data: response.data as T,
        dateTime: response.headers.date,
        status: response.status,
        message: response.data.message,
      }
    } catch (error) {
      const result = (error as unknown as { response: { data: unknown } }).response.data
      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'BaseService.callByAxios',
        endpoint,
        errorObject: JSON.stringify(error),
      })

      const errorObj = {
        status: (result as { status: number })?.status || 500,
        message: (result as { message: string })?.message || error,
        dateTime: (result as { dateTime: string })?.dateTime,
      }

      return Promise.reject(errorObj)
    }
  }

  static async callByBridge<T>(config: BaseRequestConfig) {
    const { endpoint, headers, data, options } = config

    // TODO: teste de integração PRD
    BaseBridge.requestAnalytics('CAP_FRONT', {
      log_front: `callByBridge endpoint: ${endpoint} headers: ${headers} data: ${data} options: ${options} `,
      REACT_APP_API_HOST: `${process.env.REACT_APP_API_HOST}`,
      REACT_APP_API_CAPITALIZATION: `${process.env.REACT_APP_API_CAPITALIZATION}`,
    })

    try {
      const method = config.method.toLowerCase()

      let bridgeResponse = {} as IWbHttpData

      switch (method) {
        case 'post':
        case 'put':
          bridgeResponse = await interWbHttp[method](endpoint, data, headers, options)
          break
        case 'get':
        case 'delete':
          bridgeResponse = await interWbHttp[method](endpoint, headers)
          break
        default:
          break
      }

      let parsedData = bridgeResponse.response as unknown as BaseResponseBack<T>

      // TODO: teste de integração PRD
      BaseBridge.requestAnalytics('CAP_FRONT', {
        log_front: `callByBridge parsedData ${parsedData}`,
      })

      if (typeof parsedData === 'string') {
        parsedData = JSON.parse(parsedData)
      }

      return {
        data: parsedData === null ? ({} as T) : (parsedData as T),
        dateTime: bridgeResponse.headers.date,
        status: bridgeResponse.httpStatus,
        message: parsedData === null ? undefined : parsedData.message,
      }
    } catch (error) {
      let parsedError
      let responseError

      if (error && typeof error === 'string') {
        parsedError = JSON.parse(error)
      }

      if (
        (parsedError && (parsedError as { message: string })?.message === 'Api error') ||
        (parsedError && (parsedError as { httpStatus: number })?.httpStatus === 401)
      ) {
        NewRelicUtils.noticeError(error as Error, {
          errorCodeRef: 'Unknonw_error_redirect',
          endpoint,
          errorObject: JSON.stringify(parsedError),
        })
      }

      responseError = (parsedError as { response: string })?.response

      if (responseError && typeof responseError === 'string') {
        responseError = JSON.parse(responseError)
      }

      const errorObj = {
        status: (responseError as { status: number })?.status || 500,
        message: (responseError as { message: string })?.message || JSON.stringify(parsedError),
        dateTime: (responseError as { dateTime: string })?.dateTime || new Date().toISOString(),
      }

      return Promise.reject(errorObj)
    }
  }

  static async doExecute<T>(config: BaseRequestConfig): Promise<BaseResponseBack<T>> {
    const iWb = InterWebviewBridge.getInstance()

    if (iWb.getEnvironment() === 'BROWSER') {
      const response = await BaseService.callByAxios<T>(config)

      return response
    }
    return BaseService.callByBridge(config)
  }
}

export default BaseService
