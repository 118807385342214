import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  height: calc(100vh - 54px);
  overflow: scroll;

  > :first-child {
    margin-top: 24px;
  }
`

export const Content = styled.div`
  margin-bottom: 40px;
`

export const IconWrap = styled.div<{ $bgColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: ${(props) => (props.$bgColor ? props.$bgColor : 'var(--primary100)')};
  border-radius: 8px;
`

export const StatusTag = styled.div`
  > div {
    margin-bottom: 8px;

    > span {
      display: flex;
      margin: 0;
    }
  }
`
