import { useCallback, useEffect, useState } from 'react'

import { Svg } from 'assets/svg/svg'
import { ActionIconEnum } from 'common/enums/ActionIcon'
import { PageTitles } from 'common/enums/PageTitles'
import { ScratchCard } from 'components'
import BaseBridge from 'config/bridge/BaseBridge'
import { useNavBar } from 'hooks/useNavBar'
import { useHistory } from 'react-router-dom'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import useBoundState from 'store'

import { Button } from '@interco/inter-ui/components/Button'
import { Carousel } from '@interco/inter-ui/components/Carousel'
import { Text } from '@interco/inter-ui/components/Text'

import * as S from './MyTickets.styles'
import {
  AnalyticsMyTicketCarousel,
  AnalyticsMyTicketHeader,
  AnalyticsMyTicketsOpen,
  AnalyticsMyTicketUse,
} from './MyTickets.tags'

export const MyTickets = () => {
  const history = useHistory()

  const tickets = useBoundState((state) => state.tickets)
  const availableTickets = useBoundState((state) => state.availableTickets)

  const [currentSlide, setCurrentSlide] = useState(0)
  const [tempCurrentSlide, setTempCurrentSlide] = useState(-1)

  const HAS_TICKETS_AFTER_SALES = Object.keys(tickets).length !== 0

  const handleBack = useCallback(() => {
    HAS_TICKETS_AFTER_SALES
      ? setTimeout(() => history.push(TypesRoutes.AFTER_SALES), 50)
      : BaseBridge.requestGoBack()
  }, [HAS_TICKETS_AFTER_SALES, history])

  useNavBar({
    navbarTitle: PageTitles.SCRATCHCARD,
    actionIcons: {
      leftIcon: ActionIconEnum.BACK,
      rightIcon: ActionIconEnum.FAQ,
    },
    backListener: handleBack,
    backButtonCallback: () => AnalyticsMyTicketHeader('10', 'back'),
    helpButtonCallback: () => AnalyticsMyTicketHeader('12', 'ajuda'),
  })

  useEffect(() => {
    BaseBridge.tacWb({
      name: 'load_sortezinha',
      module: 'capitalizacao',
      actions: ['enter_tickets_carousel'],
      params: {},
    })
  }, [])

  useEffect(() => {
    if (availableTickets.length > 0) {
      AnalyticsMyTicketsOpen(`${availableTickets.length}`)
    }
  }, [availableTickets])

  const renderScratchcards = () => {
    if (availableTickets[currentSlide] && currentSlide !== tempCurrentSlide) {
      const actionId = `Sortezinha ${availableTickets[currentSlide].luckyNumber}`
      AnalyticsMyTicketCarousel(actionId)
      setTempCurrentSlide(currentSlide)
    }

    return availableTickets.map((card, index) => (
      <S.SliderContent key={card.ticketId}>
        <ScratchCard scale={index !== currentSlide ? 400 : 500} onClick={handleClickTicket}>
          {Svg.Gift}
          <Text variant="body-3" bold>
            Sortezinha
          </Text>
          <Text variant="body-1" as="h3" bold>
            {card.luckyNumber}
          </Text>
        </ScratchCard>
      </S.SliderContent>
    ))
  }

  const handleClickTicket = () => {
    useBoundState.setState({
      isAllScratch: false,
      currentTicket: availableTickets[currentSlide],
    })
    AnalyticsMyTicketUse(`Sortezinha ${availableTickets[currentSlide].luckyNumber}`)
    history.push(TypesRoutes.GAME)
  }

  const renderStickFooter = (
    <S.Footer>
      <Button
        data-testid="btn-use-ticket"
        variant="secondary"
        fullWidth
        onClick={handleClickTicket}
      >
        Usar Sortezinha
      </Button>
    </S.Footer>
  )

  return (
    <S.Container>
      <Text variant="headline-h1" as="h1" semiBold>
        Escolha qual quer raspar
      </Text>

      <Text variant="body-3">{`${availableTickets.length} disponíveis`}</Text>

      <S.Slider>
        <Carousel
          dots
          arrows={false}
          infinite={false}
          speed={1000}
          slidesToShow={1}
          dotsType="medium"
          verticalMode={false}
          centerMode
          centerPadding="115px"
          className="slider"
          beforeChange={(_oldIndex, newIndex) => {
            setCurrentSlide(newIndex)
          }}
        >
          {renderScratchcards()}
        </Carousel>
      </S.Slider>

      {renderStickFooter}
    </S.Container>
  )
}
