import { defaultError } from 'common/mocks/ResponseError'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import AfterSalesService from 'service/AfterSalesService'
import TicketDetailsService from 'service/TicketDetailsService'
import useBoundState from 'store'
import { errorHandling, redirectRoute } from 'store/utils/provider'
import { sortRaffles } from 'utils/commons'
import { StateCreator } from 'zustand'

import { CompleteScratchcardState, ICompleteScratchcardRequest } from './types'

export const createCompleteScratchcardSlice: StateCreator<
  CompleteScratchcardState,
  [],
  [],
  CompleteScratchcardState
> = () => ({
  getCompleteScratchcard: async (payload) => {
    useBoundState.getState().setIsLoading(true)

    const { ticketId } = payload as ICompleteScratchcardRequest

    const ticketPromise = AfterSalesService.getTickets()
    const ticketDetailsPromise = TicketDetailsService.getTicketDetails(ticketId)
    const registrationFormPromise = TicketDetailsService.getRegistrationForm()

    try {
      const [ticketResponse, ticketDetailsResponse] = await Promise.all([
        ticketPromise,
        ticketDetailsPromise,
        registrationFormPromise,
      ])

      if (ticketDetailsResponse.raffles.length > 0) {
        ticketDetailsResponse.raffles = sortRaffles(ticketDetailsResponse.raffles)
      }

      useBoundState.setState({
        tickets: ticketResponse,
        currentTicketDetails: ticketDetailsResponse,
      })

      redirectRoute(payload)
    } catch (error) {
      errorHandling(error as Error, 'TicketsService.getCompleteScratchcard', {
        ...defaultError,
        route: TypesRoutes.START,
      })
    } finally {
      useBoundState.getState().setIsLoading(false)
    }
  },
})
