import { StateCreator } from 'zustand'

import { CheckoutState, IFormOfPayment } from './types'

export const createCheckoutSlice: StateCreator<CheckoutState, [], [], CheckoutState> = (set) => ({
  formOfPayment: {
    title: 'Formas de pagamento',
    subTitle: '',
    type: '',
  } as IFormOfPayment,

  setFormOfPayment: (formOfPayment) => set(() => ({ formOfPayment })),
})
